.enrollment h2{
    color:#087FFF ;
}
.enrollment .program .card h6{
    color:#087FFF ;
}
.enrollment .cv h4{
    color:#087FFF ;
}
.enrollment .cv .card{
    display: flex;
    align-items: center;
    justify-content: center;
}
.enrollment .cv .card img{
    border-radius: 50%;
}
.enrollment .contact{
    display: flex;
    align-items: center;
    gap: 10px;
}
.enrollment .contact button{
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
}
.enrollment .contact button svg{
    font-size: 20px;
}
@media screen and (max-width: 768px){
    .cv .card{
        margin-bottom: 10px;
    }
}
