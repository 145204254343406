
.loading-screen {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  .loading-screen__logo {
    transform-origin: center;
    animation: logoAnimation 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    
 
    
  }
  
  @keyframes logoAnimation{
      50%{
          transform: scale(1.4)
      }
  }
  