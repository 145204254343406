
    .blog h4{
        padding-top: 100px;
        color: black;
    }
    .blog-post {
      -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
      transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
    }
    
    .blog-post .blog-img .overlay,
    .blog-post .blog-img .post-meta {
      position: absolute;
      opacity: 0;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
    
    .blog-post .blog-img .overlay {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .blog-post a{
      color:#087FFF !important;
    }
    
    .blog-post .blog-img .post-meta {
      bottom: 5%;
      right: 5%;
      z-index: 1;
    }
    
    .blog-post .content .title {
      font-size: 18px;
    }
    
    .blog-post .content .title:hover {
      color: #6dc77a !important;
    }
    
    .blog-post .content .author .name:hover {
      color:#063465 !important;
    }
    
    .blog-post{
      margin: 10px;
    }
    
    
    .blog-post:hover .blog-img .post-meta {
      opacity: 1;
    }
    
    .blog-post .post-meta .like i,
    .profile-post .like i {
      -webkit-text-stroke: 2px #dd2427;
      -webkit-text-fill-color: transparent;
    }
    
    
    .text-muted {
        color:black !important;
    }
    
    
    .para-desc {
        max-width: 600px;
    }
    .text-muted p {
      color:black !important;
    }
    
    .section-title .title {
        letter-spacing: 0.5px;
        font-size: 30px;
    }