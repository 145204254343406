/* UserNavbar.css */
.cccc .container {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.cccc .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #087FFF;
    color: black;
    padding: 12px 20px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1111111;
}

.cccc .iconButton {
    background: none;
    border: none;
    color: black;
    cursor: pointer;
    padding: 10px;
}

.cccc .profileContainer {
    position: relative;
}

.cccc .profileButton {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: black;
    cursor: pointer;
    padding: 10px;
}

.cccc .dropdownMenu {
    position: absolute;
    top: 50px;
    right: 0;
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    min-width: 150px;
    display: flex;
    flex-direction: column;
}

.cccc .dropdownItem {
    background: none;
    border: none;
    padding: 10px;
    text-align: left;
    width: 100%;
    cursor: pointer;
    color: #333;
    font-size: 14px;
}

/* Media Query for mobile responsiveness */
@media screen and (max-width: 768px) {
    .cccc .navbar {
        flex-direction: row;
        padding: 10px;
    }

    .cccc .iconButton {
        padding: 8px;
    }
}
