@media only screen and (max-width: 900px) {
    .mobile {
        display: none !important;
    }

    
    .about-container .aboutus-details .about-container{
        width: 50px;
        margin-top: 100px;
    }
   .about-container .aboutus-image{
        display: none;
    }

    .about-container .aboutus-details .aboutus-content{
        margin-top: 5px;
        min-height: 170vh;
    }
    .about-container .aboutus-details .aboutus-content h2{
        font-size: 15px;
    }
    .about-container .aboutus-details .aboutus-content p{
        font-size: 16px;
        padding-left: 15px;
        padding-right: 15px;
        line-height: 1.8;
    }


}
.about-container{
    padding-top: 30px;
    background-color: white;
    min-height: 150vh;
}
.about-container .aboutus-image img{
    width: 100%;
}
.about-container .aboutus-content{
    box-shadow: -6px 2px 9px rgb(146, 143, 143);
    border-radius: 15px;
    z-index: 10;
    background-color: transparent;
    width: 100%;
    
}
.about-container .aboutus-content h2{
    padding-top: 25px;
    text-align: center;
    color: #087FFF;
    padding-bottom: 8px;
}
.about-container .aboutus-content p{
    padding-left: 40px;
    padding-top: 10px;
    font-size: 17px;
    line-height: 1.5rem;
}