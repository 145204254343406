.explore_sidebar {
    width: 250px;
    margin-top: 20px;
    background-color: #f3f4f6;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid rgb(213, 213, 213);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: fixed;
}

.ExploreCourse .content-explore {
    margin-left: 270px;
    margin-top: 30px;
    padding: 0px 40px;
}

.ExploreCourse .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

/* Table header styling */
.ExploreCourse .table th {
    background-color: #f8f9fa;
    padding: 12px 15px;
    text-align: left;
    font-weight: bold;
    border: 1px solid #ddd;
}

/* Table cell styling */
.ExploreCourse .table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
    vertical-align: middle;
}

/* Hover effect for rows */
.ExploreCourse .table tr:hover {
    background-color: #f1f1f1;
}

/* Table header hover effect */
.ExploreCourse .table th:hover {
    background-color: #e9ecef;
}

/* Responsive Table Styling */
@media (max-width: 768px) {
    .ExploreCourse .table {
        font-size: 14px;
    }

    .ExploreCourse .table th,
    .table td {
        padding: 10px;
    }
}

.ExploreCourse .explore_sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.ExploreCourse .syllabus-container {
    font-family: Arial, sans-serif;
    margin: 20px;
}

.ExploreCourse .syllabus-container h1 {
    text-align: center;
    color: #2c3e50;
}

.ExploreCourse .syllabus-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.ExploreCourse .syllabus-table th,
.syllabus-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
}

.ExploreCourse .syllabus-table th {
    background-color: #2980b9;
    color: white;
}

.ExploreCourse .syllabus-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.ExploreCourse .courses-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.ExploreCourse .courses-list li {
    font-size: 16px;
    color: #34495e;
}

.ExploreCourse .fee-table-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 800px;
    margin: 0 auto;
}

.ExploreCourse  h2 {
    text-align: center;
    font-family: 'Arial', sans-serif;
    margin-bottom: 20px;
}

.ExploreCourse .fee-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Arial', sans-serif;
}

.ExploreCourse .fee-table th,
.fee-table td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
}

.ExploreCourse .fee-table th {
    background-color: #4CAF50;
    color: white;
}

.ExploreCourse .fee-table td {
    background-color: #f4f4f4;
}

.ExploreCourse .fee-table tr:nth-child(even) td {
    background-color: #f9f9f9;
}

.ExploreCourse .fee-table tr:hover {
    background-color: #e9e9e9;
}

.ExploreCourse .explore_sidebar li {
    padding: 0.5rem;
    /* p-2 */
    margin: 20px 0px;
    cursor: pointer;
    border-radius: 0.5rem;
    /* rounded-lg */
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    /* transition-colors duration-300 */
}

.ExploreCourse .explore_sidebar li:hover {
    background-color: #e5e7eb;
    /* hover:bg-gray-200 */
}

.ExploreCourse .explore_sidebar li.active {
    background-color: #3b82f6 !important;
    color: white;
}