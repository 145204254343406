.divider:after,
.divider:before {
content: "";
flex: 1;
height: 1px;
background: #eee;
}
.h-custom {
height: calc(100% - 73px);
}
@media (max-width: 450px) {
    .loginlogo{
        top: 0;
        position: sticky;
    }
    .loginlogo img{
        width: 130px;
        height: 40px;
    }
.h-custom {
height: 100%;
}

}