/* General Sidebar Styles */
.sidebar {
       width: 100%;
    /* Default width for desktop */
    height: auto;
    margin-top: 20px;
    background-color: #fffefe;
    /* color: rgb(81, 0, 255); */
    color:  #087FFF;
    padding-top: 20px;
    z-index: 1000;
    box-shadow: 2px 0 5px rgba(198, 198, 198, 0.3);
    transition: 0.3s;
}

.sidebarListMobiles {
    display: none;

}
.sidebarList .active {
    background-color: #087FFF !important;
    color: white;
}

.sidebarList,
.sidebarListMobile {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.sidebarItem {
    padding: 15px 20px;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* Align icons and text to the left */
}

.sidebarItem:hover {
    background-color: #087FFF;
    color: white;
}
.sidebarList .active {
    background-color: #087FFF;
    color: white;
}

/* For Mobile Devices */
@media screen and (max-width: 768px) {
    .sidebar {
        width: 100%;
        /* Full width for mobile */
        height: auto;
        /* Height auto for mobile */
        padding: 0;
        /* No top padding */
    }

    .sidebarList {
        display: none;
        /* Hide the desktop items for mobile */
    }

    .sidebarListMobiles {
        display: flex;
        position: fixed;
        bottom: 0px;
        padding: 20px;
        background-color: rgb(255, 255, 255);
        width: 100%;
        justify-content: space-evenly;
        box-shadow: 1px 1px 20px #ebeaea;
        z-index: 1000;
    }

    .sidebarItem {
        font-size: 18px;
        /* Adjust font size for mobile */
        padding: 10px;
        /* Reduced padding for mobile */
    }

    .sidebarItem svg {
        font-size: 24px;
        /* Larger icons on mobile */
    }
}

/* For Desktop */
@media screen and (min-width: 769px) {
    .sidebarList {
        display: flex;
        flex-direction: column;
    }

    .sidebarListMobile {
        display: none;
    }

  

    .sidebarItem {
        font-size: 18px;
        padding: 20px;
        /* Standard padding for desktop */
    }

    .sidebarItem svg {
        font-size: 20px;
        /* Icon size for desktop */
    }
}