nav .logo-header {
    width: 200px;
}

.scrolled {
    position: fixed;
    width: 100%;
    z-index: 12;
    top: 0;
}

.top-header {
    position: op;
    width: 100%;
    z-index: 12;
    top: 0;
}

nav .logo-header img {
    width: 70%;
}

nav .logo-header h1 {
    font-size: 20px;
}

nav .top-header {
    padding: 5px;
}
.modal-content{
    width: 0%;
}
.input-form{
    text-align: start !important;
}
.home-heading .form-container h4{
    color: #087FFF;
}
.form-container small{
    font-size: 13px;
}

@media only screen and (max-width: 900px) {
    .mobile {
        display: none !important;
    }
    
    nav .logo-header img {
        width: 50%;
    }
    .home-heading {
        height: 220px !important;
        background-image: url('../Assets/banners/edu_home_mobile.webp') !important;
        background-size: 100%;
        
    }

    .home-heading-title {
        padding-bottom: 29px;
    }

    .home-heading-title h1 {
        font-size: 1.2rem;
        font-weight: 700;

    }

    .home-heading-title p {
        font-size: 0.8em !important;
    }

    .home-page1 h2{
        font-size: 17px;
        color:#087FFF;
    }
    .home-page1 h3{
        font-size: 17px;
        color: black;
    }


    .home-btn {
        font-size: 0.7em;
    }

    .home-heading-bottom {
        height: 270px;
        background-image: url("../Assets/banners/bg.webp") !important;
        color: white;
        background-size: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        /* padding-top: 50px; */
        background-repeat: no-repeat;
    }

    .home-heading-bottom h1{
        font-size: 15px;
    }
    .home-heading-bottom p{
        font-size: 14px;
    }
    .home-page1 .modal-content{
        display: none;
    }
    .courselist {
        display: none !important;
    }

    .degreelist {
        display: none !important;
    }
    .course .university-content h5{
        font-size: 12px;
    }
    .course .university-content h6{
        font-size: 8px;
    }
    .course .course-slider .slider-container{
        display: block !important;
    padding: 0px 10px;

    }
    .course .course-slider .slider-container button{
        background-color:#ece6e6 ;
        color:#087FFF;
         border-radius: 5px;
        font-size: 12px;
        border: none;
        height: 45px;
        width: 120px;
        margin-right: 10px;
        padding: 10px;
        font-weight: bold;
        text-wrap: nowrap;
        overflow-x: hidden;
    }
.course .course-slider .slider-container button:active {
    background-color: #087FFF;
    color: white;
}
.course .course-slider .slider-container button:hover {
    background-color: #087FFF;
    color: white;
}

.course .viewall{
    
}
    .university{
         /* padding: 0px 20px;  */
    }
    .academic-heading .card {
        height: 45vh;
        margin: 10px;
    }

    .academic-heading .card .academic-details {
        position: relative;
    }

    .academic-contact-details {
        position: absolute;
        margin-top: -100px;
    }
    .academic-heading .card .academic-contact-details{
        transition: transform 0.3s ease-in-out; 
    }
    
    .academic-heading .card .academic-details :hover {
        transform: scale(1.2);
    }


    .item-main::before {
        width: 50px;
    }

    .items img {
        width: 50px;
    }
}



.home-heading {
    height: 320px;
    background-image: url('../Assets/banners/edu_home.webp');
    background-size: 100%;
    display: flex;
    justify-content: start;
    text-align: center;
    align-items: center;
    background-repeat: no-repeat;

}
.home-heading .mobilee{
    margin-left: 20%;
}
.home-heading .mobilee button{
    padding: 10px;
    border-radius: 5px;
}
.home-heading-bottom {
    height: 170px;
    background-image: url('../Assets/landing/Frame\ 2_4x.webp');
    color: white;
    background-size: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    /* padding-top: 50px; */
    background-repeat: no-repeat;
}
.home-page1 h2{
    color: #087FFF;
}
.home-page1 h3{
    color: black;
}


.home-borders {
    border-top: 1px solid rgb(204, 201, 201);
    border-bottom: 1px solid rgb(204, 201, 201);
    padding: 20px;

}

.home-btn {
    background-color: #087FFF;
    border-radius: 30px;
    padding: 5px;
    text-transform: capitalize;
    color: white;
    border: none;
}

.courselist {
    display: flex;
    gap: 10px;
    float: right;
}

.courselist button {
    border: none;
    color: rgb(7, 7, 7);
    border-radius: 5px;
    background-color: white;
    border-radius: 0px !important;
    cursor: pointer;
    transition: background 0.3s ease;

}

.courselist button.active {
    border-bottom: 2px solid #087FFF;
}

.courselist button:hover {
    border-bottom: 2px solid #087FFF;
}

.degreelist {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: #f8f9fa;
    padding: 15px;
    border-radius: 8px;

}

.degreelist h5 {
    margin: 0;
    color: #333;
}

.degreelist ul {
    list-style: none;
    padding: 0;
}

.degreelist li {
    margin: 5px 0;
}

.degreelist a {
    text-decoration: none;
    color: #0b0b0b;
    transition: color 0.3s ease;

}

.degreelist a:hover {
    color:#087FFF;
}

.degreelist .dree {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    margin-right: 10px;
    padding: 1px;
    border-radius: 0px;
    margin-bottom: 10px;
    cursor: pointer;
}

.degreelist .dree.active {
    border-bottom: 2px solid #087FFF;
    padding: 3px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
}
.course h2{
    text-align: center;
    color:black;
    padding-bottom: 5px;
}
.course .course-slider .slider-container{
    display: none;
}

.course .university .card {
    margin: 0px;
    padding: 15px;
    cursor: pointer;


}

.course .university img {
    width: 100%;
}
.course .view-course{
    border-radius: 5px;
}

.coursenames input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.course_listitems {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    height: 160px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #087FFF #f8f9fa;
}

.course_listitems::-webkit-scrollbar {
    width: 5px;
}

.course_listitems::-webkit-scrollbar-thumb {
    background: #087FFF;
    border-radius: 15px;
}

.course_listitems::-webkit-scrollbar-track {
    background: #f8f9fa;
}


.course_listitems label {
    display: flex;
    align-items: center;
    gap: 5px;
}

/* form */




.academic-heading {
    padding: 2px 10%;
    position: relative;
}

.academic-heading h4 {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 2%;
    color: #087FFF;
}

.academic-details {
    height: 30vh;
}

.academic-heading .card {
    margin: 2px;
}

.academic-details img {
    height: 150px;
    width: 150px;
}

.academic-details h5 {
    text-align: center;
    padding-top: 10px;
    font-size: 14px;
}

.academic-details h6 {
    text-align: center;
    font-size: 14px;
    color: red;
}

.academic-contact-details {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    left: 0;
    margin-left: -10px;
}

.university-content h5 {
    font-size: 17px;
    text-align: center;
    padding-top: 10px;
}

.university-content h6 {
    font-size: 10px;
}

.university-content img {
    height: 100px;
}
.uni-content .register_form h3{
    text-transform: uppercase;
    font-size: 20px;
}


.course {
    padding: 20px;
}

.university .card:hover {}

.academic-contact-details button {
    padding: 10px;
    height: 30px;
    border: none;
    border-radius: 5px;
    margin: 2px;
    font-size: 10px;

}

.academic-contact-details svg {
    font-size: 15px;
    padding-right: 5px;
}

.academic-contact-details .call {
    background-color:#087FFF;
    color: white;

}

.academic-contact-details .whatsapp {
    background-color: darkgreen;
    color: white;
}

.academic-contact-details .mail {
    background-color: rgb(81, 81, 164);
    color: white;
}
.alumni-heading{
    background-color: #087FFF;
    height: 100vh;
}
.alumni-heading h4{
    text-align: center;
    margin-top: 80px;
    color:white;
    padding-top: 15px;
}
.alumni-heading h2{
    text-align: center;
    color:black;
    padding-top: 2px;
    padding-bottom: 2px;
}
.alumni-heading h5{
    text-align: center;
    color:white;
}
.alumni-images {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
   }
  
    
  .alumni-images:hover {
    animation-play-state: paused; 
  }
  .alumni-images::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

}

.alumni-heading .student-alumni-details{
    animation: slide 20s infinite linear;
    display: inline-block;
    margin-left: 60px;
    margin-top: 50px;
    border-radius: 10px;
}
  .student-alumni-details img {
    height: 130px;
    width: 130px;
    background-color: white;
    border-radius: 2px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

  }
    
.alumni-images .student-alumni-details h6 {
    text-align: center;
    padding-top: 10px;
    color:black;
    background-color: rgb(225, 213, 213);
    padding-bottom: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

}


/* university form */



.form {
    border: 1px solid black;
    height: 400px;
    width: 100%;
    background-color: white;
}

.uni-content .cost {
    text-transform: capitalize;
    font-size: 1em;
    background-color:gold;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 5px;
    margin-left: -20px;
    color: red;
    margin-left: -15px;
}

.uni-content .register_form .form_sumbit button{
    width: 100%;
}

.form .input-form {
    position: relative;

}


.form .input-form label {
    position: absolute;
    top: -12px;
    z-index: 2;
    background-color: white;
    color: black;
}


.uni-content {

    color: whitesmoke;
    background-color: #087FFF;

}

/* image animation */

.item-main {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.item-main:hover .items {
    animation-play-state: paused;
}

.item-main::before {
    content: "";
    position: absolute;
    height: 100px;
    width: 120px;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 10;

}
.site-map{
    background-color: #cedbe9;
}
.site-map .onmba{
    background-color:white;
    
}
.site-map .onmba h5{
    color:darkblue;
    text-align: center;
}
.site-map .course-names{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}
.site-map .course-names button{
    border: none;
    color: #087FFF;
    padding: 6px 8px;
    border-radius: 5px;
    background-color:rgb(239, 235, 235);
}




@media only screen and (max-width: 900px) {
    .item-main::before {
        width: 20px;
    }

    .items img {
        width: 80px !important;
    }

    .item-main {
        margin: 50px 0px;
    }

}

.uni-content .university_form {
    padding: 1%;
}

.uni-content .register_form {
    background-color: white;
    padding: 10px;
    color: black;
    border-radius: 5px;
}

.uni-content .form_sumbit {
    display: flex;
    justify-content: center;
    margin-top: 7px;
}

.items {

    animation: slide 20s infinite linear;
    display: inline-block;
    margin-left: 60px;

}

.items img {
    width: 200px;
    box-shadow: -6px 2px 9px rgb(199 195 195);
    border-radius: 10px;
}


@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-600%);
    }
}


/* faq */
.faq {
    background-color: #dfdede;
}

.faq_button {

    padding: 10px;
    border-radius: 2px;
}

.faq_button .faq_inner {
    background-color: white;
    border-radius: 10px;
}

.faq_button .faq_inner button {
    width: 100%;
    background-color: white;
    border: 1px solid rgb(151, 148, 148);
    padding: 10px;
    text-align: start;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}


.footer .circule {
    background-color: white;
    width: 40px;
    border-radius: 50%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: wheat;
    margin-right: 10px;
    margin-top: 20px;
    font-size: 25px;
}

.footer_icons {
    padding: 0px 50px;
}

.media_icons {

    display: flex;

}

.footer {
    padding: 30px;
    margin-top: 10px;
}

.footer_items h6 {
    cursor: pointer;
}

.footer_items h6:hover {
    text-decoration: underline;
    transition: 7s;
}

.ptag_ft p{
  margin-bottom: 0%;
}

